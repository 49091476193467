import React, { useState, useEffect } from "react";
import "./Loader.css";
import yandes from "./Assets/yandes.svg";

const Loader = () => {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % 10); // Switches between 0 to 9
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const loadingMessages = [
    "Hang tight! Yandes is loading 🔄",
    "Loading your Yandes experience ⏳",
    "Just a moment, Yandes is loading 🕒",
    "Loading Yandes awesomeness 🚀",
    "Sit tight, we're loading up Yandes! 🛋️",
    "Your Yandes journey is loading 🌐",
    "Loading Yandes magic ✨",
    "Loading... patience is a virtue on Yandes! 🧘‍♂️",
    "Loading Yandes wonders 🌟",
    "One moment please, Yandes is loading ⏱️",
  ];
  
  return (
    <div className="loader-wrapper">
      <div className="items-center justify-center flex flex-col text-center">
        <div className="loader"></div>
        <img src={yandes} alt="yandes-logo" className="mt-10" />
        <h1 className="mt-4 text-white">{loadingMessages[loadingMessageIndex]}</h1>
      </div>
    </div>
  );
};

export default Loader;
