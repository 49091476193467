import React, { useEffect, useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loader from "./Loader";

// Lazy load components
const Home = React.lazy(() => import("./Pages/Home"));
const Error = React.lazy(() => import("./Pages/Error"));
const CheckoutPage = React.lazy(() => import("./Pages/CheckoutPage"));
const Contact = React.lazy(() => import("./Pages/Contact"));

function App() {
  return (
    <div>
      <Suspense fallback={<Loader />}>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/404" element={<Error />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
